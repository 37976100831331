import React from "react";
import PropTypes from "prop-types";

export const DataBoltIcon = ({ size = 24, fill = "#000", ...rest }) => {
  return (
    <svg
      {...rest}
      fill={fill}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 400 400"
    >
      <path d="M79.6,134.21c24.74,7.57,55.43,13.18,89.5,15.28l-32.92,67.39c-69.42-9.33-118.9-33.46-118.9-61.72V98.18 c0-0.47,0.41-0.81,0.81-0.81c0.34,0,0.61,0.14,0.74,0.41c0,0.07,0,0.13,0.07,0.2C25.45,111.3,47.56,124.41,79.6,134.21z" />
      <path d="M382.7,98.05c0,0,0,0.07,0,0.14v56.98c0,32.51-65.5,59.55-151.62,64.89l55.83-77.13 c48.8-9.13,83.55-25.75,94.23-44.95c0.07-0.07,0.07-0.07,0.07-0.14c0.14-0.27,0.41-0.47,0.74-0.47c0.27,0,0.54,0.2,0.68,0.41 C382.63,97.84,382.7,97.98,382.7,98.05z" />
      <ellipse cx="200.02" cy="68.35" rx="182.71" ry="65.85" />
      <path d="M79.6,222.49c24.4,7.44,54.55,12.98,88.01,15.21l-32.85,67.26C66.08,295.5,17.27,271.5,17.27,243.45V186.4 c0-0.41,0.41-0.74,0.81-0.74c0.34,0,0.61,0.13,0.74,0.41c0,0.07,0,0.13,0.07,0.2C25.45,199.58,47.56,212.69,79.6,222.49z" />
      <path d="M382.7,186.26v57.19c0,35.89-79.63,65.03-178.73,65.84l53.4-73.68c64.15-7.3,111.06-26.5,123.77-49.35 c0.07-0.07,0.07-0.14,0.07-0.14c0.14-0.27,0.41-0.47,0.74-0.47c0.27,0,0.54,0.13,0.68,0.41 C382.63,186.13,382.7,186.19,382.7,186.26z" />
      <path d="M167.67,325.92l-32.51,67.33c-68.88-9.46-117.89-33.46-117.89-61.58v-56.98c0-0.47,0.41-0.81,0.81-0.81 c0.34,0,0.61,0.2,0.74,0.41c0,0.07,0,0.2,0.07,0.27c6.56,13.25,28.66,26.36,60.7,36.16C104,318.14,134.21,323.68,167.67,325.92z" />
      <path d="M382.7,274.54c0,0.07,0,0.07,0,0.14v56.98c0,36.37-81.79,65.84-182.71,65.84c-21.9,0-42.86-1.42-62.32-3.92 l69.83-66.65c89.16-1.22,157.84-24,173.66-52.46c0.07,0,0.07-0.07,0.07-0.13c0.14-0.27,0.41-0.47,0.74-0.47 c0.27,0,0.54,0.2,0.68,0.47C382.63,274.41,382.7,274.47,382.7,274.54z" />
    </svg>
  );
};

DataBoltIcon.propTypes = {
  size: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired
};

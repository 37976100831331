import React from "react";

export const JoinIcon = ({ size, fill, ...rest }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 1068.000000 996.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <metadata>
        Created by potrace 1.16, written by Peter Selinger 2001-2019
      </metadata>
      <g
        transform="translate(0.000000,996.000000) scale(0.100000,-0.100000)"
        fill={fill}
        stroke="none"
      >
        <path
          d="M1896 9943 c-180 -26 -379 -103 -541 -210 -126 -84 -329 -283 -404
-398 -163 -249 -233 -489 -234 -800 0 -412 128 -723 417 -1010 135 -134 227
-201 373 -274 189 -94 341 -130 583 -138 202 -6 326 9 487 59 223 69 390 173
573 357 275 276 401 565 416 951 16 385 -102 718 -359 1012 -106 122 -308 273
-460 343 -88 41 -286 100 -384 114 -98 15 -346 12 -467 -6z"
        />
        <path
          d="M8360 9953 c-327 -38 -615 -185 -857 -436 -188 -195 -303 -413 -359
-683 -22 -108 -27 -156 -27 -289 -2 -408 126 -727 403 -1006 134 -135 243
-216 395 -290 202 -99 384 -139 625 -139 247 0 414 37 615 135 308 150 528
368 676 670 105 215 145 414 136 670 -11 311 -84 532 -256 775 -228 323 -567
531 -951 585 -101 14 -313 18 -400 8z"
        />
        <path
          d="M5120 8529 c-416 -34 -808 -198 -1140 -477 -464 -389 -723 -884 -771
-1469 -20 -247 11 -568 77 -798 141 -491 507 -956 979 -1240 424 -256 996
-346 1523 -239 389 78 731 260 1031 549 385 371 596 799 652 1324 15 140 6
446 -16 581 -76 456 -276 833 -617 1166 -203 197 -346 300 -571 409 -340 166
-712 229 -1147 194z"
        />
        <path
          d="M9950 7107 c-25 -7 -78 -32 -118 -55 -41 -23 -77 -42 -81 -42 -3 0
-22 -11 -41 -24 -77 -52 -386 -180 -590 -245 -240 -75 -313 -86 -590 -86 -292
0 -360 10 -649 94 -40 12 -76 19 -79 17 -3 -3 0 -45 7 -93 13 -98 17 -385 7
-508 -20 -232 -96 -515 -206 -765 -50 -115 -94 -195 -172 -317 -33 -51 -57
-97 -53 -103 3 -5 26 -10 50 -10 67 0 269 -28 370 -52 208 -48 419 -139 598
-257 134 -89 209 -151 344 -289 l112 -114 458 5 c501 5 559 10 736 67 258 83
450 233 542 425 74 154 80 191 81 505 3 823 -115 1414 -335 1686 -103 127
-270 196 -391 161z"
        />
        <path
          d="M555 7096 c-67 -22 -107 -46 -168 -104 -200 -192 -317 -598 -366
-1272 -11 -160 -17 -343 -16 -490 2 -204 5 -246 23 -318 45 -172 127 -299 265
-408 140 -111 336 -194 528 -224 45 -7 260 -14 539 -17 l465 -5 150 149 c172
169 286 254 465 347 148 76 277 125 420 160 112 27 311 56 386 56 28 0 47 5
50 14 3 7 -22 55 -56 107 -74 114 -185 338 -235 473 -46 126 -99 331 -120 471
-24 157 -30 399 -15 576 7 83 10 152 7 155 -2 3 -56 -11 -119 -30 -63 -19
-186 -46 -273 -61 -148 -25 -176 -27 -375 -22 -167 3 -236 9 -310 25 -132 29
-259 66 -365 107 -49 19 -133 51 -185 71 -52 20 -185 84 -295 143 l-200 106
-80 2 c-46 2 -97 -3 -120 -11z"
        />
        <path
          d="M3215 4623 c-74 -5 -240 -32 -325 -54 -98 -24 -204 -66 -326 -127
-283 -142 -521 -402 -711 -777 -123 -242 -184 -412 -262 -725 -106 -431 -157
-856 -166 -1385 -6 -362 37 -601 151 -829 200 -399 593 -658 1084 -716 68 -8
843 -10 2745 -8 l2650 4 100 22 c307 70 530 187 720 377 215 215 336 485 374
837 27 247 3 774 -55 1177 -68 479 -186 897 -340 1203 -184 366 -400 621 -658
777 -83 50 -112 64 -286 133 -178 70 -511 116 -625 86 -51 -14 -215 -114 -525
-321 -149 -100 -346 -205 -441 -236 -20 -6 -53 -20 -74 -31 -22 -11 -45 -20
-52 -20 -7 0 -46 -11 -86 -25 -237 -84 -492 -125 -767 -125 -314 0 -558 46
-890 167 -241 88 -304 122 -671 364 -124 82 -265 169 -314 194 -94 48 -98 48
-250 38z"
        />
      </g>
    </svg>
  );
};

import React from "react";

export const ByodIcon = ({ size, fill, ...rest }) => {
  return (
    <svg
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 169.14 169.18"
    >
      <path
        d="M52.48,18.59c9.21,3.82,18.89,5.54,28.73,6.44a229.66,229.66,0,0,0,27.39.76,169,169,0,0,0,27-2.67,63.93,63.93,0,0,0,14.12-3.86,17.35,17.35,0,0,0,5.46-3.42c1.91-1.94,1.84-4.07-.16-5.93a13.16,13.16,0,0,0-2.49-1.69A43.28,43.28,0,0,0,141.81,4.1c-8.32-2.2-16.84-3-25.37-3.68a9.41,9.41,0,0,1-3-.42H91.79c-.22.24-.53.21-.81.24C85.39.72,79.79,1,74.22,1.72A81.53,81.53,0,0,0,56,5.87,28.45,28.45,0,0,0,48.43,10a3.34,3.34,0,0,0-.63,5.18A11.68,11.68,0,0,0,52.48,18.59Z"
        fill={fill}
      />
      <path
        d="M166.17,136.57c.78-.7,1.52-1.43,2.28-2.15a1.41,1.41,0,0,0,.15-2.32c-1.9-2.29-4.4-2.72-7.14-2.22a23.43,23.43,0,0,0-11.8,6.15,26.15,26.15,0,0,1-13.84,7c-5.59.85-11.2,1.51-16.82,2a44.32,44.32,0,0,1-16.56-1.52c-3.15-.91-6.08-2.41-9.19-3.44-5-1.68-10.2-3-15.36-4.33-1.87-.46-1.82-.44-1.16-2.15a2.59,2.59,0,0,1,2.72-1.83c1.66,0,3.32,0,5-.1,3.23-.13,6.47-.29,9.7-.43a48.56,48.56,0,0,1,8.09,0c8.81,1.13,17.61,2.39,26.37,3.84,3.06.51,5.64,0,7.6-2.55a10,10,0,0,0,2-6.26,4.86,4.86,0,0,0-2.9-4.66,34.67,34.67,0,0,0-5.7-2.3,106.63,106.63,0,0,0-23.83-3.67A45,45,0,0,1,90.17,112c-2.31-1-4.43-2.39-6.7-3.47a53.24,53.24,0,0,0-23.66-5c-1.73,0-3.46,0-5.18.17A94.37,94.37,0,0,0,33.51,108,3.1,3.1,0,0,0,31,111.2c-.22,2.62-.44,5.24-.7,7.85Q29.21,130.72,28,142.36c-.1,1,.21,1.32,1.14,1.42a44.62,44.62,0,0,1,6.38,1c4,1,7.65,2.93,11.35,4.68C61,156.2,75,163.06,89.58,168.74a3.07,3.07,0,0,0,1,.34,49.65,49.65,0,0,0,7-.06c6.88-.92,13.73-2.08,20.6-3.08,5.33-.78,10.62-1.92,16-2.31a19.26,19.26,0,0,0,9.68-3.57c6.64-4.59,13.33-9.08,20-13.61a12.77,12.77,0,0,0,3.17-3c1.71-2.3,1.44-4.26-.88-5.9C165.65,137.11,165.75,136.93,166.17,136.57Z"
        fill={fill}
      />
      <path
        d="M156.5,70.5c.06-3.06,0-6.12.07-9.18a3.32,3.32,0,0,0-.22-1.58,1.39,1.39,0,0,0-.3.16,4.35,4.35,0,0,0-.57.49,18,18,0,0,1-6.44,4,52.29,52.29,0,0,1-6.66,2.17c-11.84,3.18-24,3.91-36.13,4.27a201.83,201.83,0,0,1-27.48-1.2,103.4,103.4,0,0,1-22-4.35c-3.73-1.23-7.34-2.64-9.63-6.13,0,0-.12,0-.18,0s-.07.05-.07.07c0,8.21-.14,16.42.1,24.63a4,4,0,0,0,1,2.46,9.88,9.88,0,0,0,3.93,3A91.12,91.12,0,0,0,63,93a147.68,147.68,0,0,0,32,3.81c7.88.2,15.76,0,23.63-.53A142.4,142.4,0,0,0,142,92.71a37.22,37.22,0,0,0,11.4-4.47,6.51,6.51,0,0,0,3.19-6.08C156.54,78.27,156.42,74.38,156.5,70.5Z"
        fill={fill}
      />
      <path
        d="M49,51.94a41.53,41.53,0,0,0,7.83,3.85c11.31,3.83,23,5,34.86,5.32,8.28.23,16.56,0,24.84-.29a119.7,119.7,0,0,0,27.15-4A30.74,30.74,0,0,0,154,52.35a5.76,5.76,0,0,0,2.6-5.06c-.1-3.06-.14-6.11-.06-9.17.11-4.43,0-8.87,0-13.3,0-.33.16-.71-.23-1a15,15,0,0,1-2.25,2.46A13.87,13.87,0,0,1,151.25,28a63.83,63.83,0,0,1-15.35,4.63c-10.89,2.1-21.92,2.68-33,2.82A195.73,195.73,0,0,1,73,33.5,82.15,82.15,0,0,1,57.34,30c-3.9-1.36-7.93-2.6-10.48-6.43,0,8.07,0,16.13,0,24.19A4.55,4.55,0,0,0,49,51.94Z"
        fill={fill}
      />
      <path
        d="M15,120.58c.53-5.23,1-10.46,1.58-15.68.1-.89-.12-1.17-1.05-1.16-3.53.07-7.06,0-10.59.06-1.39,0-1.78.39-2,1.84-.37,3-.74,6-1.14,9A148.39,148.39,0,0,0,.06,138.25,102.9,102.9,0,0,0,.4,149.61c.1,1.66.17,3.31.26,5,0,.5.14.85.81.85,4,0,7.93,0,11.89,0,.57,0,.69-.19.65-.78a166.15,166.15,0,0,1-.13-20C14.11,130,14.49,125.27,15,120.58Z"
        fill={fill}
      />
      <path
        d="M156.42,94.74c-3.13,3.39-4.24,4.1-8.4,5.6-9.62,3.45-19.63,4.66-29.73,5.39-4.24.31-8.48.49-12.77.72.11.33.32.35.54.38,5.34.53,10.66,1.15,16,2a55.25,55.25,0,0,1,15.75,4.32,16.76,16.76,0,0,1,6.6,5.1,15,15,0,0,1,2.55,7.35c.1.84.25,1.17,1.16.64,1.74-1,3.51-2,5.34-2.83a5.09,5.09,0,0,0,3.17-5.14c0-7.1,0-14.19,0-21.29C156.57,96.23,156.48,95.54,156.42,94.74Z"
        fill={fill}
      />
      <path
        d="M23.91,110.67c-1.41.06-2.81,0-4.22,0-.66,0-.91.17-1,.87A142.44,142.44,0,0,0,16.53,137c-.06,2.2.16,5.33.28,8.46,0,1-.18,2.3.36,2.91s2,.16,3,.24c.18,0,.37,0,.54,0,.58.08.74-.2.78-.73.29-3.48.59-7,.91-10.43.39-4.37.8-8.74,1.2-13.11s.75-8.45,1.15-12.68C24.81,110.93,24.66,110.64,23.91,110.67Z"
        fill={fill}
      />
    </svg>
  );
};

import React from "react";
import PropTypes from "prop-types";

export const HelpDeskIcon = ({ size = 24, fill = "#000", ...rest }) => {
  return (
    <svg
      {...rest}
      fill={fill}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 175.15 114.28"
    >
      <polygon points="25.4,90.1 25.4,90.1 25.4,90.1" />
      <polygon points="151.8,90.1 151.8,90.1 151.8,90.1" />
      <path d="M149.3,85.4l0.5-5.6c0.5-5.1-3.2-9.5-8.2-10.5c0.3-6.1,0-11.7-0.8-16.8c-3.4-28.1-25.7-49.9-52.7-49.9 c-26,0-47.6,20.1-52.2,46.7c-1.3,6-1.8,12.8-1.4,20.5c-4.1,1.5-6.9,5.5-6.5,10l0.5,5.6c0.5,5.4,5.4,9.3,10.9,8.8 c0.9-0.1,1.7-0.3,2.5-0.6c4.2-1.5,7-5.5,6.6-10l-0.5-5.6c-0.5-4.8-4.5-8.5-9.3-8.8c-0.1,0-0.2,0-0.4,0 c0.6-13.2,5.9-49.1,45.3-56.1c1.5-0.1,3-0.2,4.6-0.2c1.5,0,2.9,0.1,4.3,0.2c39.7,7,44.9,43.3,45.4,56.4c-4.2,0.8-7.6,4.2-8,8.6 l-0.5,5.6c-0.4,4.5,2.4,8.6,6.6,10c0.8,0.3,1.6,0.5,2.5,0.6c1.8,0.2,3.6-0.2,5.1-0.9c1.2,2.4,2.1,5.2,2,8.1 c-0.1,3.7-1.9,6.8-5.4,9.7c-6,4.9-13.3,3.9-19.5,1.2c-1.2,1.3-2.5,2.5-3.9,3.6c4.1,2,8.8,3.6,13.6,3.6c4.4,0,8.8-1.3,12.9-4.7 c4.6-3.7,7-8.2,7.1-13.2c0.2-4.2-1.3-8.2-3.1-11.4C148.5,88.8,149.1,87.2,149.3,85.4z" />
      <path d="M98.6,108.4c-5.8,0-10.5-4.6-10.5-10.2c0-5.6,4.7-10.2,10.5-10.2c5.8,0,10.5,4.6,10.5,10.2c0,2-0.6,3.8-1.6,5.4 c1.4,1.3,6.8,5.9,13.3,8.8c4.5-4.6,8.2-10.1,10.9-16.3c-5-1.8-8.3-6.6-7.8-12l0.6-6.6c0.5-5.8,5.4-10.2,11.1-10.6 c-3.3-25.4-23.3-45-47.5-45c-24.2,0-44.2,19.6-47.5,45C46.7,66.7,52,71,52.8,77l0.9,6.6c0.8,5.9-3.2,11.3-9,12.7 c4.7,10.8,12.6,19.6,22.4,24.8c6.3,3.4,13.5,5.3,21,5.3c7.5,0,14.7-1.9,21-5.3c2.8-1.5,5.3-3.2,7.8-5.2c-6.6-3.3-11.6-7.7-13-9 C102.3,107.9,100.5,108.4,98.6,108.4z" />
    </svg>
  );
};

HelpDeskIcon.propTypes = {
  size: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired
};
